<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div v-if="!claimed">
        <TaskBlocker :assignee="data.assignee" />
      </div>
      <div v-if="isLoading">
        <v-alert
          text
          dense
          color="teal"
          border="left"
          class="mx-4 my-4 px-4 py-4"
        >
          Daten werden geladen...
        </v-alert>
      </div>
      <div v-if="!isLoading">
        <CustomerDetails
          v-if="customerData"
          :customerId="customerId"
          :data="customerData"
          :parseSalutation="true"
          title="Kundeninformationen"
        />
        <DisplayContainer label="Vertragsinformationen">
          <div class="input-row">
            <DataDisplay label="Vertragsnummer">
              <div v-if="serviceContractId !== null">
                {{ serviceContractId }}
              </div>
            </DataDisplay>
            <DataDisplay label="Adresse">
              <div>
                {{ installationAddress.street }} {{ installationAddress.houseNumber }}{{ installationAddress.houseNumberSupplement }}
              </div>
              <div>
                {{ installationAddress.zipCode }} {{ installationAddress.city }}
              </div>
            </DataDisplay>
          </div>
        </DisplayContainer>
        <DisplayContainer label="Projektinformationen">
          <div class="input-row">
            <DataDisplay label="Projekt ID">
              <div v-if="projectId !== undefined && projectId != null">
                {{ projectId }}
              </div>
              <div v-else>
                -
              </div>
            </DataDisplay>
            <DataDisplay label="Projekt Name">
              <div v-if="projectName !== null">
                {{ projectName }}
              </div>
              <div v-else>
                -
              </div>
            </DataDisplay>
          </div>
        </DisplayContainer>
        <DisplayContainer label="Gebuchte Produkte">
          <AllOrderedProductsTable :allProducts="contractProducts" />
        </DisplayContainer>
      </div>

      <div
        v-if="!isLoading"
        class="buttons-wrapper"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              large
              v-bind="attrs"
              :disabled="buttonsDisabled"
              v-on="on"
              @click="showDowngradeDoneDialog = !showDowngradeDoneDialog"
            >
              Downgrade durchgeführt
            </v-btn>
          </template>
          <span>Ein Downgrade wurde akzeptiert und die Produkte wurden angepasst</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary"
              large
              v-bind="attrs"
              :disabled="buttonsDisabled"
              v-on="on"
              @click="showDowngradeRejectedDialog = !showDowngradeRejectedDialog"
            >
              Downgrade abgelehnt, Prozess beenden
            </v-btn>
          </template>
          <span>Ein Downgrade wurde abgelehnt, der Prozess wird beendet</span>
        </v-tooltip>
      </div>
    </div>
    <v-dialog
      v-model="showDowngradeDoneDialog"
      width="20vw"
    >
      <v-card>
        <v-card-title> Vertrag weiter prozessieren </v-card-title>
        <v-card-text>
          <p>
            Ein Downgrade wurde akzeptiert, und die Daten wurden im CRM korrekt hinterlegt sodass der Prozess weiter laufen kann?
          </p>
          <v-spacer></v-spacer>
          <div class="dialog-buttons">
            <v-btn
              small
              color="secondary"
              class="align-center"
              @click="showDowngradeDoneDialog = !showDowngradeDoneDialog"
            >
              Abbrechen
            </v-btn>
            <v-btn
              small
              color="primary"
              class="align-center"
              @click="completeTask('accepted')"
            >
              Ja, weiter prozessieren
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDowngradeRejectedDialog"
      width="20vw"
    >
      <v-card>
        <v-card-title> Prozess beenden </v-card-title>
        <v-card-text>
          <p>
            Downgrade wurde abgelehnt, der Prozess wird beendet.
          </p>
          <v-spacer></v-spacer>
          <div class="dialog-buttons">
            <v-btn
              small
              color="secondary"
              class="align-center"
              @click="showDowngradeDoneDialog = !showDowngradeDoneDialog"
            >
              Abbrechen
            </v-btn>
            <v-btn
              small
              color="primary"
              class="align-center"
              @click="completeTask('rejected')"
            >
              Ja, Prozess beenden
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'ActionDowngradeAnbietenTask',
  components: {
    AllOrderedProductsTable: () =>
      import('@/components/AllOrderedProductsTable.vue'),
    CustomerDetails: () => import('@/components/CustomerDetails.vue'),
    DataDisplay: () => import('@/components/elements/DataDisplay.vue'),
    DisplayContainer: () =>
      import('@/components/elements/DisplayContainer.vue'),
    TaskBlocker: () => import('@/task/TaskBlocker.vue')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    serviceContractId: null,
    project: null,
    projectId: null,
    customerId: null,
    customerData: null,
    installationAddress: null,
    showDowngradeDoneDialog: false,
    showDowngradeRejectedDialog: false
  }),
  computed: {
    buttonsDisabled() {
      return this.isLoading || !this.claimed;
    },
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    projectName() {
      return this.project
        ? `${this.project.city}, ${this.project.district}`
        : null;
    }
  },
  created: function () {
    this.loadTaskData(this.data.id);
  },
  methods: {
    loadTaskData: function (id) {
      this.isLoading = true;
      HTTP.get(`/task/${id}/variables`)
        .then((resp) => {
          this.serviceContractId = resp.data.serviceContractId.value;
          this.projectId = resp.data.projectId.value;
          this.customerId = resp.data.serviceCustomerId.value;
          return Promise.all([
            this.fetchCustomerInfo(this.serviceContractId),
            this.loadContractProducts(this.serviceContractId),
            this.fetchInstallationAddress(this.serviceContractId),
            this.fetchProjectInfo(this.projectId)
          ]);
        })
        .catch(() => {
          this.showToast('error', 'Fehler beim laden der Prozessinformationen');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchCustomerInfo(contractId) {
      return HTTP.get(`/customer/addressByType/${contractId}/INVOICE`).then(
        (resp) => {
          this.customerData = resp.data;
          this.customerData.mobil = this.customerData.cellPhoneNumber;
        }
      );
    },
    loadContractProducts: function (contractId) {
      return HTTP.get(`/products/contract/all/${contractId}`).then((resp) => {
        this.contractProducts = resp.data;
      });
    },
    fetchInstallationAddress(contractId) {
      return HTTP.get(
        `/customer/addressByType/${contractId}/INSTALLATION`
      ).then((resp) => {
        this.installationAddress = resp.data;
      });
    },
    fetchProjectInfo(projectId) {
      return HTTP.get(
        `/past/projects?where[projektId][equals]=${projectId}`
      ).then((resp) => {
        if (!resp.data || resp.data.length < 1) return;
        this.project = resp.data[0];
      });
    },
    completeTask: function (variableValue) {
      HTTP.post(`/engine/default/task/${this.data.id}/complete`, {
        variables: {
          checkTariffAfterDowngradeResult: {
            type: 'String',
            value: variableValue
          }
        }
      })
        .then(() => {
          this.showToast('info', 'Der Abschluss der Aufgabe war erfolgreich!');
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() => this.showToast('error', 'Ein Fehler ist aufgetreten'));
    },
    showToast: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    }
  }
};
</script>
<style scoped>
.dialog-buttons {
  text-align: right;
}
.dialog-buttons :first-child {
  margin-right: 5px;
}
.buttons-wrapper .v-btn {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-1);
  float: right;
}
.technology-state-icon {
  margin-right: 5px;
  bottom: 2px;
}
</style>
